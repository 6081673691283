@tailwind base;
@tailwind components;
@tailwind utilities;


.loading.cover-content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loading.cover-page {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading .ant-spin {
    color: #3e79f7;
}

:where(.css-dev-only-do-not-override-15tf7pd).ant-pro-card {
    background-color: transparent;
    padding: 0;
}
